import type { Permissions, WAES, WAESAPIV1 } from '../../../../../../../../common';
import type { DateRange } from '@/interface/dateRange';

import { message, Spin } from 'antd';
import { type FC, useEffect, useMemo, useRef, useState } from 'react';

import WaesAPIV1 from '@/api/services/waesApiV1';
import { useServiceToken } from '@/hooks/services/useServiceToken';
import { LocaleFormatter, useLocale } from '@/locales';

import { Services } from '../../../../../../../../common';
import InvitationsTable from './InvitationsTable';

type InvitationListProps = {
    /**
     * Current user permissions
     */
    userPermissions: Permissions.Permission[];
    /**
     * Customer ID
     */
    customerId: string;
    /**
     * Date range
     */
    dateRange: DateRange;
    /**
     * Embedded signup base URL
     */
    embeddedSignupBaseUrl?: string;
    /**
     * Number of items to show
     */
    limit: number;
    sortOrder: 'asc' | 'desc';
};

const InvitationList: FC<InvitationListProps> = ({
    userPermissions,
    customerId,
    dateRange,
    embeddedSignupBaseUrl = '',
    limit,
    sortOrder,
}) => {
    const tokenParams = useMemo(() => ({}), []);
    const { token: currentToken } = useServiceToken(userPermissions, Services.Name.waesApiV1, tokenParams);
    const { formatMessage } = useLocale();
    const [loading, setLoading] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const waesApiV1Ref = useRef(new WaesAPIV1(embeddedSignupBaseUrl));
    const [list, setList] = useState<WAES.Invitation[]>([]);
    const initialFetchCompletedRef = useRef(false);

    const validToken = currentToken;

    useEffect(() => {
        // reset on customer change
        setHasError(false);
        setLoading(false);
    }, [validToken, customerId]);

    useEffect(() => {
        // Fetch invitations with filters
        if (validToken && initialFetchCompletedRef.current) {
            getInvitations(validToken, {
                limit,
                before: dateRange.till,
                after: dateRange.from,
                sortOrder,
            });
        }
    }, [validToken, limit, dateRange, sortOrder]);

    useEffect(() => {
        // Fetch invitations without filters when customerId changes
        if (validToken && customerId) {
            getInvitations(validToken, { customerId }).then(() => {
                initialFetchCompletedRef.current = true;
            });
        }
    }, [validToken, customerId]);

    const getInvitations = async (token: string, params: WAESAPIV1.Invitations.GetRequest) => {
        setLoading(true);
        setHasError(false);

        return waesApiV1Ref.current
            .getInvitations(token, {
                ...params,
            })
            .then(({ data }) => {
                if (data) {
                    setList(data.invitations);
                } else {
                    setHasError(true);
                }
            })
            .catch((error) => {
                console.error('Error sending whatsapp signup invitation', error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const deleteInvitation = async (invitationCode: string) => {
        if (!validToken) {
            throw new Error('Token is not valid');
        }

        const { data } = await waesApiV1Ref.current.deleteInvitation(validToken, invitationCode);

        if (data.success) {
            message.success(formatMessage({ id: 'whatsapp.embeddedSignup.invitations.deleteSuccess' }));
            getInvitations(validToken, { customerId, limit, before: dateRange.till, after: dateRange.from });
        } else {
            console.error('Error deleting whatsapp signup invitation');
        }
    };

    if (loading || !validToken) {
        return <Spin spinning className="app-loading-wrapper transparent" />;
    }

    if (hasError) {
        return (
            <div>
                <LocaleFormatter id="global.errors.dataLoading" />
            </div>
        );
    }

    return (
        <div className="content">
            <InvitationsTable
                list={list}
                limit={limit}
                embeddedSignupBaseUrl={embeddedSignupBaseUrl}
                deleteInvitation={deleteInvitation}
            />
        </div>
    );
};

export default InvitationList;

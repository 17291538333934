import type { AccessGrants } from '../../../../common/routes/access-grants';
import type { Customer as C } from '../../../../common/routes/customer';
import type { IUser } from '../../../../common/users/interfaces';
import type { FC } from 'react';

import './index.less';

import { message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import MyButton from '@/components/basic/button';
import MyInput from '@/components/basic/input';
import MySelect from '@/components/basic/select';
import { ExpireDatePicker } from '@/components/basic/shared/ExpireDatePicker';
import MyForm from '@/components/core/form';
import { LocaleFormatter, useLocale } from '@/locales';
import {
    createAccessGrantAsync,
    updateAccessGrantAsync,
} from '@/stores/accessGrants.action';
import {
    loadCustomerChannelsAsync,
    loadCustomerChatsAsync,
    loadCustomerRolesAsync,
} from '@/stores/customer.action';
import { loadCustomersAsync } from '@/stores/customers.action';
import { hasPermissions } from '@/stores/user.action';
import { loadUsersAsync } from '@/stores/users.action';

import { Customer } from '../../../../common/customer';
import { Permission } from '../../../../common/permissions';
import AccessPreview from './components/AccessPreview';

const AccessRequestPage: FC = () => {
    const dispatch = useDispatch();
    const { state } = useLocation();
    const [formInstance] = useForm();
    const { formatMessage } = useLocale();
    const navigate = useNavigate();
    const { customers } = useSelector((state) => state.customers);
    const { user } = useSelector((state) => state.user);
    const { users } = useSelector((state) => state.users);

    const [selectedCustomer, setSelectedCustomer] = useState<
        Customer.Info['_id']
    >(state?.grant.customerId);
    const [selectedUser, setSelectedUser] = useState<IUser['_id'] | null>(
        user?._id ?? null
    );
    const [selectedChannel, setSelectedChannel] = useState<string | null>(null);
    const [selectedType, setSelectedType] = useState<Customer.AccessChatType[]>(
        [Customer.AccessChatType.list]
    );
    const [selectedChat, setSelectedChat] = useState<string | null>(null);
    const [selectedRole, setSelectedRole] = useState<string>('');

    const [roles, setRoles] = useState<C.Roles.ListResponse | null>({});
    const [channels, setChannels] =
        useState<C.Channels.Accounts.ListResponse | null>([]);
    const [chats, setChats] = useState<C.Chats.SearchResponse | null>(null);

    const isEdit = state?.isEdit;
    const grant: Customer.AccessGrant = state?.grant || {};
    const isAdmin = dispatch(
        hasPermissions([Permission.customersAccessGrantApprove])
    );

    const fetchCustomerData = async (customerId: string) => {
        const [roles, channels] = await Promise.all([
            dispatch(loadCustomerRolesAsync(customerId)),
            dispatch(loadCustomerChannelsAsync(customerId)),
        ]);

        setRoles(roles);
        setChannels(channels);
    };

    const fetchChats = async (
        channel: C.Channels.Accounts.Account,
        query?: string
    ) => {
        const chats = await dispatch(
            loadCustomerChatsAsync(selectedCustomer, channel, query)
        );

        setChats(chats);
    };

    const onChatSearchDebounced = useCallback(
        debounce(async (query: string) => {
            const channel = channels?.find(
                (c) => c.accountId === selectedChannel
            );

            if (channel) {
                debounce(() => {
                    fetchChats(channel, query);
                }, 500)();
            }
        }, 500),
        [selectedChannel, channels]
    );

    const onCustomersearchDebounced = useCallback(
        debounce((search: string) => {
            dispatch(
                loadCustomersAsync({ search, limit: 50 })
            );
        }, 500),
        []
    );

    const onChatSearch = (query: string) => {
        if (query.length >= 2) {
            onChatSearchDebounced(query);
        }
    };

    const onCustomerSearch = (search: string) => {
        if (search.length >= 2) {
            onCustomersearchDebounced(search);
        }
    };

    const onFormSubmit = async (values: any) => {
        const channel = channels?.find(
            (c) => c.accountId === values.chatAccess?.channel
        );

        const formData: AccessGrants.PostBody = {
            customerRoles: [values.customerRoles],
            requestMessage: values.requestMessage,
            customerId: selectedCustomer,
            uid: selectedUser ? selectedUser : undefined,
        };

        if (values.expires) {
            formData.expires = dayjs(values.expires).unix() * 1000;
        }

        if (channel) {
            formData.customerChatsAccess = [
                {
                    type: selectedType,
                    name: channel?.channel,
                    accountId: channel?.accountId,
                    ...(selectedChat && { id: selectedChat }),
                },
            ];
        }

        let success = false;
        let successMessage = '';

        if (isEdit) {
            success = Boolean(
                await dispatch(updateAccessGrantAsync(formData, grant._id))
            );
            successMessage = formatMessage({
                id: 'customers.access.requestAccessUpdateSuccess',
            });
        } else {
            success = Boolean(await dispatch(createAccessGrantAsync(formData)));
            successMessage = formatMessage({
                id: 'customers.access.requestAccessCreateSuccess',
            });
        }

        if (success) {
            navigate('/customers/access');
            message.success(successMessage);
        }
    };

    useEffect(() => {
        if (selectedCustomer) {
            fetchCustomerData(selectedCustomer);
        }
    }, [selectedCustomer]);

    useEffect(() => {
        /* When navigating directly to the url */
        if (Object.keys(users).length === 0) {
            dispatch(loadUsersAsync());
        }
    }, [users]);

    useEffect(() => {
        const channel = channels?.find((c) => c.accountId === selectedChannel);

        if (channel) {
            setSelectedChat(null);
            formInstance.setFieldsValue({ customerChats: null });
        }
    }, [selectedChannel]);

    useEffect(() => {
        dispatch(loadCustomersAsync({ reset: true }));
    }, []);

    useEffect(() => {
        if (grant.customerId) {
            /* Controled fields that conditionally depend on each other must be set explicitly */
            setSelectedCustomer(grant.customerId);
            setSelectedUser(grant.uid);
            setSelectedChannel(
                grant.customerChatsAccess?.[0]?.accountId || null
            );
            setSelectedChat(grant.customerChatsAccess?.[0]?.id || null);
        }
    }, [grant]);

    return (
        <div className="container">
            <div className="title">
                <h1>
                    {isEdit ? (
                        <LocaleFormatter id="customers.access.editAccess" />
                    ) : isAdmin ? (
                        <LocaleFormatter id="customers.access.grantAccess" />
                    ) : (
                        <LocaleFormatter id="customers.access.requestAccess" />
                    )}
                </h1>
            </div>
            <div className="request-content">
                <MyForm
                    form={formInstance}
                    layout="vertical"
                    className="request-form-container"
                    initialValues={{
                        customerId: grant.customerId,
                        userId: grant.uid || selectedUser,
                        requestMessage: grant.requestMessage,
                        customerRoles: grant.customerRoles?.[0],
                        chatAccess: {
                            channel: grant.customerChatsAccess?.[0]?.accountId,
                            id: grant.customerChatsAccess?.[0]?.id,
                            type:
                                grant.customerChatsAccess?.[0]?.type ||
                                selectedType,
                        },
                        expires: grant.expires ? dayjs(grant.expires) : dayjs().startOf('day').add(23, 'hour').add(55, 'minute'),
                    }}
                    onFinish={onFormSubmit}
                >
                    <MyForm.Item
                        label={
                            <LocaleFormatter id="customers.access.selectUser" />
                        }
                        name="userId"
                        hidden={!isAdmin}
                    >
                        <MySelect
                            value={selectedUser}
                            onChange={setSelectedUser}
                            options={Object.values(users).map((c: IUser) => ({
                                label: c.name || c.email,
                                value: c._id,
                            }))}
                        />
                    </MyForm.Item>
                    <MyForm.Item
                        label={
                            <LocaleFormatter id="customers.access.selectCustomer" />
                        }
                        name="customerId"
                        rules={[
                            {
                                required: true,
                                message: formatMessage({
                                    id: 'customers.access.rolesRequired',
                                }),
                            },
                        ]}
                    >
                        <MySelect
                            value={selectedCustomer}
                            onChange={setSelectedCustomer}
                            onSearch={onCustomerSearch}
                            options={customers.map((c) => ({
                                label: c._id,
                                value: c._id,
                            }))}
                            showSearch
                            allowClear
                        />
                    </MyForm.Item>
                    <MyForm.Item
                        label={<LocaleFormatter id="customers.access.roles" />}
                        name="customerRoles"
                        hidden={!selectedCustomer}
                        rules={[
                            {
                                required: true,
                                message: formatMessage({
                                    id: 'customers.access.rolesRequired',
                                }),
                            },
                        ]}
                    >
                        <MySelect
                            value={selectedRole}
                            onChange={setSelectedRole}
                            options={Object.values(roles || {}).map(
                                ({ name, title }) => ({
                                    label: title,
                                    value: name,
                                })
                            )}
                        />
                    </MyForm.Item>
                    <fieldset
                        className="request-form-chats-content"
                        hidden={!selectedCustomer}
                    >
                        <legend>
                            <>
                                <LocaleFormatter id="customers.access.chats" />{' '}
                                (optional)
                            </>
                        </legend>
                        <MyForm.Item
                            label={
                                <LocaleFormatter id="customers.access.channels" />
                            }
                            name={['chatAccess', 'channel']}
                            hidden={!selectedCustomer}
                        >
                            <MySelect
                                value={selectedChannel}
                                onChange={setSelectedChannel}
                                options={(channels || []).map((c) => ({
                                    label: !c.title
                                        ? c.accountId
                                        : `${c.title} - ${c.accountId}`,
                                    value: c.accountId,
                                }))}
                                allowClear
                            />
                        </MyForm.Item>
                        <MyForm.Item
                            label={
                                <>
                                    <LocaleFormatter id="customers.access.chatsFilter" />
                                </>
                            }
                            name={['chatAccess', 'id']}
                            hidden={!selectedCustomer}
                            key={selectedChannel}
                        >
                            <MySelect
                                key={selectedChannel}
                                value={selectedChat}
                                onChange={setSelectedChat}
                                onSearch={onChatSearch}
                                disabled={!selectedChannel}
                                placeholder={formatMessage({
                                    id: 'customers.access.searchChat',
                                })}
                                options={(chats?.data || []).map((c) => ({
                                    label: `${c.title} - ${c.channelInfo.id}`,
                                    value: c.channelInfo.id,
                                }))}
                                filterOption={(
                                    input: string,
                                    option?: { label: string; value: string }
                                ) =>
                                    (option?.label ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                notFoundContent={
                                    <div className="no-chat-found">
                                        {formatMessage({
                                            id: 'customers.access.noChatsFound',
                                        })}
                                    </div>
                                }
                                showSearch
                                allowClear
                            />
                        </MyForm.Item>
                        <MyForm.Item
                            label={
                                <LocaleFormatter id="customers.access.selectActions" />
                            }
                            name={['chatAccess', 'type']}
                            hidden={!selectedCustomer}
                        >
                            <MySelect
                                value={selectedType}
                                onChange={setSelectedType}
                                disabled={!selectedChannel}
                                options={Object.keys(
                                    Customer.AccessChatType
                                ).map((option) => ({
                                    label: option,
                                    value: option,
                                }))}
                                mode="multiple"
                                allowClear
                            />
                        </MyForm.Item>
                    </fieldset>
                    <MyForm.Item
                        label={
                            <>
                                <LocaleFormatter id="customers.access.requestMessage" />{' '}
                                (optional)
                            </>
                        }
                        name="requestMessage"
                        hidden={!selectedCustomer || isAdmin}
                    >
                        <MyInput />
                    </MyForm.Item>
                    {selectedCustomer && isAdmin && (
                        <MyForm.Item
                            label={
                                <LocaleFormatter id="customers.access.expiresAt" />
                            }
                            name="expires"
                            rules={[
                                {
                                    required: true,
                                    message: formatMessage({
                                        id: 'customers.access.expiresAtRequired',
                                    }),
                                },
                            ]}
                        >
                            <ExpireDatePicker />
                        </MyForm.Item>
                    )}
                    <MyForm.Item hidden={!selectedCustomer}>
                        <MyButton type="primary" htmlType="submit">
                            <LocaleFormatter id="global.submit" />
                        </MyButton>
                    </MyForm.Item>
                </MyForm>
                {!!selectedCustomer && !!selectedUser && !!user && (
                    <AccessPreview
                        selectedCustomer={selectedCustomer}
                        selectedUser={selectedUser}
                        selectedRole={selectedRole}
                        selectedChannel={selectedChannel}
                        selectedChat={selectedChat}
                        selectedType={selectedType}
                        currentUser={user._id}
                    />
                )}
            </div>
        </div>
    );
};

export default AccessRequestPage;

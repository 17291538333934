import type { Users } from '../../../../common';

import { difference } from 'lodash';
import { useEffect, useState } from 'react';

import { Services } from '../../../../common';

import P = Services.Permissions;

interface Props<Service extends Services.Name, Permission = P.ServicePermissionsType[Service]> {
    /**
     * User to check permissions for
     */
    user: Users.IUser;
    /**
     * Service name to check permissions for
     */
    service: Service;
    /**
     * Permissions to check access to
     */
    permissions: Permission[];
    /**
     * Render function to return contents to display when access is denied. 
     * If not provided, nothing will be rendered.
     */
    renderOnDenied?: () => React.ReactNode;
}

type IServicePermissionGate<Service extends Services.Name = Services.Name> = React.FC<Props<Service>>;

/**
 * Component that checks if user has access to all of specified permissions of service.
 * If access is allowed, children are rendered, 
 * otherwise `renderOnDenied` is rendered if provided, or nothing if not.
 */
const ServicePermissionGate: IServicePermissionGate = ({
    user,
    service,
    permissions,
    children,
    renderOnDenied
}) => {

    type Permission = P.ServicePermissionsType[typeof service];
    const permissionMap = Services.Permissions.map[service];

    const [permitted, setPermitted] = useState<boolean>(false);

    useEffect(() => {
        const servicePermissionList = Services.Permissions.getServicePermissionsList<Permission>(
            permissionMap,
            user.permissions,
            true
        );
        const missing = difference(permissions, servicePermissionList);

        setPermitted(missing.length === 0);
    }, [user.permissions, permissions]);

    if (permitted) {
        return <>{children}</>;
    } else {
        return <>{renderOnDenied ? renderOnDenied() : null}</>;
    }
};

export default ServicePermissionGate;

import './InvitationsEditPage.less';

import { type FC } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import { LocaleFormatter } from '@/locales';

import InvitationEdit from './components/InvitationEdit';

const InvitationsEditPage: FC = () => {
    const { state } = useLocation();
    const { config } = useSelector((state) => state.global);
    const { user } = useSelector((state) => state.user);

    const invitation = state?.invitation;

    /* If invitation is not present, navigate user back to the invitations list */
    if (!invitation) {
        return <Navigate to="/channels/whatsapp/embedded-signup/invitations" />;
    }

    return (
        <div className="container container-with-filters">
            <div className="title">
                <h1>
                    <span className="subtitle whatsapp-subtitle">Whatsapp</span>
                    <LocaleFormatter id="whatsapp.embeddedSignup.invitations.edit.title" />
                </h1>
                <div className="title-actions"></div>
            </div>
            <div className="content">
                <InvitationEdit
                    userPermissions={user!.permissions}
                    customerId={invitation.customerId}
                    embeddedSignupBaseUrl={config?.whatsappEmbeddedSignup?.baseUrl}
                    invitation={invitation}
                />
            </div>
        </div>
    );
};

export default InvitationsEditPage;

import type { FC } from 'react';

import { Navigate } from 'react-router-dom';

import PageMenu from '@/components/layout/PageMenu/PageMenu';
import useNavigationItems from '@/hooks/useNavigationItems';

const WhatsappPage: FC = () => {
    const { items } = useNavigationItems((item) => item.code === 'whatsapp');

    if (items.length === 1) {
        return <Navigate to={items[0].path} />;
    }

    return <PageMenu titleId="title.whatsapp" items={items} />;
};

export default WhatsappPage;

import type { FC } from 'react';

import PageMenu from '@/components/layout/PageMenu/PageMenu';
import useNavigationItems from '@/hooks/useNavigationItems';

const ChannelsPage: FC = () => {
    const { items } = useNavigationItems((item) => item.code === 'channels');

    return <PageMenu titleId="title.channels" items={items} />;
};

export default ChannelsPage;

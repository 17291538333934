import type { MenuList } from '@/interface/layout/menu.interface';
import type { MessageDescriptorId } from '@/locales';

import './index.less';

import { Card } from 'antd';
import * as antdicons from 'react-icons/ai';
import { Link } from 'react-router-dom';

import { LocaleFormatter } from '@/locales';
import { history } from '@/routes/history';

interface Props {
    titleId: MessageDescriptorId;
    items: MenuList;
}

/**
 * PageMenu is a component that displays a grid of submenus for a given page. It should be used as a top-level wrapper
 *
 * @param titleId - title of the page, as defined in locales
 * @param items - items to be displayed in the menu
 * @returns A card grid of submenus (if any) for a given page
 */
const PageMenu: React.FC<Props> = ({ titleId, items }) => {
    return (
        <div className="container">
            <div className="title">
                <h1>
                    <LocaleFormatter id={titleId} />
                </h1>
                <div className="title-actions"></div>
            </div>
            <div className="page-navigation-with-submenu">
                {items.map((channel: MenuList[number]) => {
                    const Icon = antdicons[channel.icon as keyof typeof antdicons];

                    return (
                        <Card
                            key={channel.code}
                            onClick={() => history.push(channel.path)}
                            title={
                                <Link className="title-container" to={channel.path}>
                                    <span>{channel.label.en_US}</span>
                                </Link>
                            }
                            className="card"
                            bordered={false}
                            hoverable
                        >
                            <Card.Meta avatar={channel.icon ? <Icon className="icon" /> : null} />
                        </Card>
                    );
                })}
            </div>
        </div>
    );
};

export default PageMenu;

import type { FC } from 'react';

import './index.less';

import { Breadcrumb } from 'antd';
import { Link, useLocation } from 'react-router-dom';

import { LocaleFormatter } from '@/locales';

const BreadcrumbComponent: FC = () => {
    const location = useLocation();
    const pathSnippets = location.pathname.split('/').filter((i) => i);

    const breadcrumbItems = [
        {
            key: 'dashboard',
            title: <Link to="/dashboard">
                <LocaleFormatter id="title.dashboard" />
            </Link>,
        },
        ...pathSnippets
            .filter((s) => s !== 'dashboard')
            .map((snippet, index) => {
                const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;

                return {
                    key: url,
                    title: <Link to={url}>{snippet.length > 21 ? snippet.slice(0, 25) + '...' : snippet}</Link>,
                };
            }),
    ];

    return <Breadcrumb className="breadcrumb-container" separator=">" items={breadcrumbItems} />;
};

export default BreadcrumbComponent;

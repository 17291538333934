import * as APIV2 from '@whatsper/api-v2';
import * as ManageAPIV1 from '@whatsper/manage-api-v1';
import * as WAES from '@whatsper/whatsapp-embedded-signup-types';
import { Permission } from '../permissions';
import { Name } from './name';

import APIV2_P = APIV2.Permission;
import ManageAPIV1_P = ManageAPIV1.Permissions.Permission;
import WAES_P = WAES.Permissions.Permission;

export namespace Permissions {
    /**
     * Map of service name to all existing service permissions
     */
    export type ServicePermissionsType = {
        [Name.customerApiV2]: APIV2_P,
        [Name.manageApiV1]: ManageAPIV1_P,
        [Name.waesApiV1]: WAES_P,
    };

    /**
     * Map of customer API v2 permissions to user permissions
     */
    export const CustomerAPIV2Map: Readonly<ServicePermissionsMap<APIV2_P>> = Object.freeze({
        [APIV2_P.metrics_view]: {
            userPermissions: [Permission.customersMetricsView],
            frontendAllowed: true,
        },
    });
    /**
     * Map of manage API v1 permissions to user permissions
     */
    export const ManageAPIV1Map: Readonly<ServicePermissionsMap<ManageAPIV1_P>> = Object.freeze({
        // To be implemented
    });

    /**
     * Map of WhatsApp embedded API v1 permissions to user permissions
     */
    export const WAESAPIV1Map: Readonly<ServicePermissionsMap<WAES_P>> = Object.freeze({
        [WAES_P.invitations_create]: {
            userPermissions: [
                Permission.setupCustomer,
                Permission.whatsappEmbeddedSignup,
            ],
            frontendAllowed: true,
        },
        [WAES_P.invitations_update]: {
            userPermissions: [
                Permission.setupCustomer,
                Permission.whatsappEmbeddedSignup,
            ],
            frontendAllowed: true,
        },
        [WAES_P.invitations_delete]: {
            userPermissions: [
                Permission.setupCustomer,
                Permission.whatsappEmbeddedSignup,
            ],
            frontendAllowed: true,
        },
        [WAES_P.invitations_read]: {
            userPermissions: [
                Permission.setupCustomer,
                Permission.whatsappEmbeddedSignup,
            ],
            frontendAllowed: true,
        },
    });

    export const map = Object.freeze({
        [Name.customerApiV2]: CustomerAPIV2Map,
        [Name.manageApiV1]: ManageAPIV1Map,
        [Name.waesApiV1]: WAESAPIV1Map,
    });

    function permissionAllowed(permissionInfo: ServicePermission, userPermissions: Permission[], isFrontend: boolean): boolean {
        if (isFrontend && !permissionInfo.frontendAllowed) {
            return false;
        }
        for (const needed of permissionInfo.userPermissions) {
            if (!userPermissions.includes(needed)) {
                return false;
            }
        }
        return true;
    }

    /**
     * Get list of all permissions of particular service that user has access to,
     * based on service permission map and user permissions
     * 
     * @param map Service permission map
     * @param userPermissions User to check permissions
     * @param frontendOnly If true, only permissions allowed for frontend will be returned
     *
     */
    export function getServicePermissionsList<P extends string>(
        map: Readonly<ServicePermissionsMap<P>>, 
        userPermissions: Permission[],
        frontendOnly: boolean = false
    ): P[] {
        return Object
            .keys(map)
            .filter((servP) => {
                const info = map[servP as P] as ServicePermission;
                return permissionAllowed(info, userPermissions, frontendOnly);
            }) as P[]; // Type is gated by function argument type, but lost after Object.keys() 
    }
    /**
     * Check is user has access to particular service permissions
     * 
     * @param map Service permissions map
     * @param permissions Service permissions to check
     * @param userPermissions User to check permissions
     * @param frontend Is access from frontend
     * @returns 
     */
    export function hasServicePermissions<P extends string>(
        map: Readonly<ServicePermissionsMap<P>>, 
        permissions: P[], 
        userPermissions: Permission[],
        frontend: boolean = false
    ): boolean {
        return Object
            .keys(map)
            .filter((servP) => {
                return permissions.includes(servP as P);
            })
            .every((servP) => {
                const info = map[servP as P] as ServicePermission;
                return permissionAllowed(info, userPermissions, frontend);
            });
    }

    export type ServicePermissionsMap<P extends string = string> = {
        [permission in P]?: ServicePermission;
    }

    export type ServicePermission = {
        /**
         * Does this permission allows access from user side running code
         */
        frontendAllowed?: true;
        /**
         * User permissions required to access this service permission
         */
        userPermissions: Permission[];
    }
}

import type { WAESAPIV1 } from '../../../../common';

import { request } from '../request';
import WaesAPI from './waesApi.abstract';

export default class WaesAPIV1 extends WaesAPI {
    getInvitations = async (token: string, params: WAESAPIV1.Invitations.GetRequest) => {
        return request<WAESAPIV1.Invitations.GetResponse>(
            'get',
            `${this.getAPIBaseUrl()}/invitations`,
            params,
            this.getRequestParams(token),
        );
    };

    createInvitation = async (token: string, params: WAESAPIV1.Invitations.CreateRequest) => {
        return request<WAESAPIV1.Invitations.CreateResponse>(
            'post',
            `${this.getAPIBaseUrl()}/invitations`,
            params,
            this.getRequestParams(token),
        );
    };

    deleteInvitation = async (token: string, invitationCode: string) => {
        return request<WAESAPIV1.Invitations.DeleteResponse>(
            'delete',
            `${this.getAPIBaseUrl()}/invitations/${invitationCode}`,
            {},
            this.getRequestParams(token),
        );
    };

    updateInvitation = async (token: string, invitationId: string, params: WAESAPIV1.Invitations.UpdateRequest) => {
        return request<WAESAPIV1.Invitations.UpdateResponse>(
            'patch',
            `${this.getAPIBaseUrl()}/invitations/${invitationId}`,
            params,
            this.getRequestParams(token),
        );
    };

    getVersion(): number {
        return 1;
    }
}

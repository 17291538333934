import type { Permissions, WAES, WAESAPIV1 } from '../../../../../../../../common';

import { message, Spin } from 'antd';
import { type FC, useEffect, useMemo, useState } from 'react';

import WaesAPIV1 from '@/api/services/waesApiV1';
import InvitationForm from '@/components/embeddedSignup/InvitationForm/InvitationForm';
import { useServiceToken } from '@/hooks/services/useServiceToken';
import { LocaleFormatter, useLocale } from '@/locales';
import { history } from '@/routes/history';

import { Services } from '../../../../../../../../common';

type InvitationEditProps = {
    /**
     * Current user permissions
     */
    userPermissions: Permissions.Permission[];
    /**
     * Customer ID
     */
    customerId: string;
    /**
     * Embedded signup base URL
     */
    embeddedSignupBaseUrl?: string;
    /**
     * Map of multi-partner solutions IDs to titles
     */
    solutions?: Record<string, string>;
    /**
     * Invitation body to prefill the form
     * */
    invitation: WAES.Invitation;
};

const InvitationEdit: FC<InvitationEditProps> = ({
    userPermissions,
    customerId,
    embeddedSignupBaseUrl = '',
    solutions,
    invitation,
}) => {
    const tokenParams = useMemo(() => ({}), []);
    const { token: currentToken } = useServiceToken(userPermissions, Services.Name.waesApiV1, tokenParams);
    const { formatMessage } = useLocale();
    const [loading, setLoading] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);

    const validToken = currentToken;

    useEffect(() => {
        // reset on customer change
        setHasError(false);
        setLoading(false);
    }, [customerId]);

    const editInvitation =
        (token: string, invitationCode: string) =>
        async (formData: Omit<WAESAPIV1.Invitations.UpdateRequest, 'customerId'>) => {
            const { data } = await new WaesAPIV1(embeddedSignupBaseUrl).updateInvitation(
                token,
                invitationCode,
                formData,
            );

            if (data.success) {
                message.success(formatMessage({ id: 'whatsapp.embeddedSignup.invitations.updateSuccess' }));
                history.push('/channels/whatsapp/embedded-signup/invitations');
            } else {
              console.error('Error updating whatsapp signup invitation');
            }
        };

    if (loading || !validToken) {
        return <Spin spinning className="app-loading-wrapper transparent" />;
    }

    if (hasError) {
        return (
            <div>
                <LocaleFormatter id="global.errors.dataLoading" />
            </div>
        );
    }

    return (
        <div className="content">
            <InvitationForm
                invitation={invitation}
                onSubmit={editInvitation(validToken, invitation.code)}
                solutions={solutions}
            />
        </div>
    );
};

export default InvitationEdit;

import type { WAESAPIV1 } from "../../../../../common";

import "./InvitationForm.less";

import dayjs from "dayjs";
import { type FC, useState } from "react";

import MyButton from "@/components/basic/button";
import MyInput from "@/components/basic/input";
import MySelect from '@/components/basic/select';
import { ExpireDatePicker } from "@/components/basic/shared/ExpireDatePicker";
import MyForm from "@/components/core/form";
import MyFormItem from "@/components/core/form-item";
import { LocaleFormatter } from "@/locales";
import removeEmptyObjects from "@/utils/removeEmptyObjects";

import { WAES } from "../../../../../common";

interface Props {
  onSubmit: (
    formData: Omit<WAESAPIV1.Invitations.CreateRequest | WAESAPIV1.Invitations.UpdateRequest, "customerId">
  ) => void;
  invitation?: WAES.Invitation;
  solutions?: Record<string, string>;
}

const SELECT_NONE_VALUE = "_none_";

const InvitationForm: FC<Props> = ({ onSubmit, solutions, invitation }) => {
  const [form] = MyForm.useForm();
  const [businessId, setBusinessId] = useState<string>("");

  const isEdit = !!invitation;

  const onFormSubmit = async () => {
    await form.validateFields();
    const values = form.getFieldsValue();

    if (values.prefilledData.preVerifiedPhone?.ids && !Array.isArray(values.prefilledData.preVerifiedPhone?.ids)) {
      values.prefilledData.preVerifiedPhone.ids =
        values.prefilledData.preVerifiedPhone.ids.split(",");
    }
  
    if (values.prefilledData.solutionID === SELECT_NONE_VALUE) {
      delete values.prefilledData.solutionID;
    }

    if (values.featureType === SELECT_NONE_VALUE) {
      delete values.featureType;
    }

    values.expires = values.expires?.valueOf();

    const cleanedValues = removeEmptyObjects(values);

    const formData: Omit<
      WAESAPIV1.Invitations.CreateRequest | WAESAPIV1.Invitations.UpdateRequest,
      "customerId"
    > = {
      ...cleanedValues,
    };

    onSubmit(formData);
  };

  return (
    <MyForm
      form={form}
      layout="vertical"
      className="register-phone-number-form"
      initialValues={{
        ...invitation?.prefilledData,
        expires: invitation?.expires ? dayjs(invitation.expires) : undefined,
      }}
      scrollToFirstError
    >
      { solutions &&
      <MyFormItem
        name={["prefilledData", "solutionID"]}
        label={<LocaleFormatter id="whatsapp.embeddedSignup.invitations.mpSolution" />}
      >
        <MySelect
          defaultValue={SELECT_NONE_VALUE}
          options={[
            { label: "None", value: SELECT_NONE_VALUE },
            ...Object.entries(solutions).map(([id, label]) => {
              return { label, value: id }
            })
          ]}
        />
			</MyFormItem>
      }
      {!isEdit && (
        <MyFormItem
          name="code"
          label={<LocaleFormatter id="whatsapp.embeddedSignup.invitations.code" />}
          extra={<LocaleFormatter id="whatsapp.embeddedSignup.invitations.codeExtra" />}
          rules={[
            {
              pattern: /^[0-9a-zA-Z_-]{10,100}$/g,
              message: (
                <LocaleFormatter id="whatsapp.embeddedSignup.invitations.codeValidation" />
              ),
            },
          ]}
        >
        <MyInput />
      </MyFormItem>
      )}
      <MyFormItem
        name={["featureType"]}
        label={<LocaleFormatter id="whatsapp.embeddedSignup.invitations.featureType" />}
      >
        <MySelect
          defaultValue={SELECT_NONE_VALUE}
          options={[
            // TODO: Labels translation
            { label: 'None (Regular)', value: SELECT_NONE_VALUE },
            { label: 'Business app onboarding', value: WAES.FeatureType.whatsapp_business_app_onboarding },
            { label: 'Only WABA sharing', value: WAES.FeatureType.only_waba_sharing },
          ]}
        />
			</MyFormItem>
      <fieldset className="invitation-form-fieldset">
        <legend>
          <LocaleFormatter id="whatsapp.businessInformation" />
        </legend>
        <MyFormItem
          name={["prefilledData", "business", "id"]}
          label={<LocaleFormatter id="whatsapp.embeddedSignup.invitations.businessId" />}
          extra={
            <LocaleFormatter id="whatsapp.embeddedSignup.invitations.businessIdExtra" />
          }
        >
          <MyInput
            value={businessId}
            onChange={(e) => setBusinessId(e.target.value)}
          />
        </MyFormItem>
        {!businessId && (
          <>
            <MyFormItem
              name={["prefilledData", "business", "name"]}
              label={
                <LocaleFormatter id="whatsapp.businessName" />
              }
            >
              <MyInput />
            </MyFormItem>
            <MyFormItem
              name={["prefilledData", "business", "email"]}
              label={
                <LocaleFormatter id="whatsapp.businessEmail" />
              }
            >
              <MyInput />
            </MyFormItem>
            <MyFormItem
              name={["prefilledData", "business", "website"]}
              label={
                <LocaleFormatter id="whatsapp.businessWebsite" />
              }
            >
              <MyInput />
            </MyFormItem>
            <MyFormItem
              name={["prefilledData", "business", "address", "streetAddress1"]}
              label={
                <LocaleFormatter id="whatsapp.businessAddress1" />
              }
            >
              <MyInput />
            </MyFormItem>
            <MyFormItem
              name={["prefilledData", "business", "address", "streetAddress2"]}
              label={
                <LocaleFormatter id="whatsapp.businessAddress2" />
              }
            >
              <MyInput />
            </MyFormItem>
            <MyFormItem
              name={["prefilledData", "business", "address", "city"]}
              label={
                <LocaleFormatter id="whatsapp.businessCity" />
              }
            >
              <MyInput />
            </MyFormItem>
            <MyFormItem
              name={["prefilledData", "business", "address", "state"]}
              label={
                <LocaleFormatter id="whatsapp.businessState" />
              }
            >
              <MyInput />
            </MyFormItem>
            <MyFormItem
              name={["prefilledData", "business", "address", "zipPostal"]}
              label={
                <LocaleFormatter id="whatsapp.businessZipPostal" />
              }
            >
              <MyInput />
            </MyFormItem>
            <MyFormItem
              name={["prefilledData", "business", "address", "country"]}
              label={
                <LocaleFormatter id="whatsapp.businessCountry" />
              }
            >
              <MyInput />
            </MyFormItem>
            <MyFormItem
              name={["prefilledData", "business", "phone", "code"]}
              label={
                <LocaleFormatter id="whatsapp.businessPhoneCode" />
              }
            >
              <MyInput />
            </MyFormItem>
            <MyFormItem
              name={["prefilledData", "business", "phone", "number"]}
              label={
                <LocaleFormatter id="whatsapp.businessPhoneNumber" />
              }
            >
              <MyInput />
            </MyFormItem>
            <MyFormItem
              name={["prefilledData", "business", "timezone"]}
              label={
                <LocaleFormatter id="whatsapp.businessTimezone" />
              }
            >
              <MyInput />
            </MyFormItem>
          </>
        )}
      </fieldset>
      <fieldset className="invitation-form-fieldset">
        <legend>
          <LocaleFormatter id="whatsapp.phone" />
        </legend>
        <MyFormItem
          name={["prefilledData", "phone", "displayName"]}
          label={
            <LocaleFormatter id="whatsapp.phone.displayName" />
          }
        >
          <MyInput />
        </MyFormItem>
        <MyFormItem
          name={["prefilledData", "phone", "category"]}
          label={<LocaleFormatter id="whatsapp.phone.category" />}
        >
          <MyInput />
        </MyFormItem>
        <MyFormItem
          name={["prefilledData", "phone", "description"]}
          label={
            <LocaleFormatter id="whatsapp.phone.description" />
          }
        >
          <MyInput />
        </MyFormItem>
      </fieldset>
      <fieldset className="invitation-form-fieldset">
        <legend>
          <LocaleFormatter id="whatsapp.embeddedSignup.invitations.preVerifiedPhone" />
        </legend>
        <MyFormItem
          name={["prefilledData", "preVerifiedPhone", "ids"]}
          label={
            <LocaleFormatter id="whatsapp.embeddedSignup.invitations.preVerifiedPhone" />
          }
          extra={
            <LocaleFormatter id="whatsapp.embeddedSignup.invitations.preVerifiedPhoneExtra" />
          }
        >
          <MyInput />
        </MyFormItem>
      </fieldset>
      <fieldset className="invitation-form-fieldset">
        <legend>
          <LocaleFormatter id="whatsapp.whatsAppBusinessAccount" />
        </legend>
        <MyFormItem
          name={["prefilledData", "whatsAppBusinessAccount", "id"]}
          label={
            <LocaleFormatter id="whatsapp.whatsAppBusinessAccountId" />
          }
        >
          <MyInput />
        </MyFormItem>
      </fieldset>
      <MyFormItem
        name="expires"
        label={<LocaleFormatter id="global.expiresAt" />}
        extra={<LocaleFormatter id="whatsapp.embeddedSignup.invitations.expiresExtra" />}
      >
        <ExpireDatePicker />
      </MyFormItem>
      <MyButton
        type="primary"
        htmlType="submit"
        size="large"
        onClick={onFormSubmit}
        className="invitation-submit-button"
      >
        {isEdit ? (
            <LocaleFormatter id="global.edit" />
        ) : (
            <LocaleFormatter id="whatsapp.embeddedSignup.invitations.generateLink" />
        )}
      </MyButton>
    </MyForm>
  );
};

export default InvitationForm;

import type { FC } from 'react';

import { Navigate } from 'react-router-dom';

import PageMenu from '@/components/layout/PageMenu/PageMenu';
import useNavigationItems from '@/hooks/useNavigationItems';

const CustomerMetricsPage: FC = () => {
    const { items } = useNavigationItems((item) => item.code === 'customerMetrics');

    if (items.length === 1) {
      return <Navigate to={items[0].path} />;
    }

    return <PageMenu titleId="customerMetrics.title" items={items} />;
};

export default CustomerMetricsPage;

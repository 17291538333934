import type { MenuList } from '@/interface/layout/menu.interface';

import { useDispatch, useSelector } from 'react-redux';

import { hasPermissions } from '@/stores/user.action';

import { Permission } from '../../../common/permissions';

export const navigationItems: MenuList = [
    {
        code: 'dashboard',
        label: {
            en_US: 'Dashboard',
        },
        icon: 'AiOutlineDashboard',
        path: '/dashboard',
    },
    {
        code: 'customers',
        label: {
            en_US: 'Customers',
        },
        icon: 'AiOutlineShop',
        path: '/customers',
        permissions: [Permission.listCustomers],
        children: [
            {
                code: 'customersList',
                label: {
                    en_US: 'Customers list',
                },
                path: '/customers',
            },
            {
                code: 'customersAccess',
                label: {
                    en_US: 'Access',
                },
                path: '/customers/access',
            },
        ],
    },
    {
        code: 'customerMetrics',
        label: {
            en_US: 'Customer Metrics',
        },
        icon: 'AiOutlineShop',
        path: '/customer-metrics',
        permissions: [Permission.customersMetricsView],
        children: [
            {
                code: 'agentsMessagesCount',
                label: {
                    en_US: 'Agents messages count',
                },
                icon: 'AiOutlineThunderbolt',
                path: '/customer-metrics/agents-messages-count',
                permissions: [Permission.customersMetricsView],
            },
        ],
    },
    {
        code: 'instances',
        label: {
            en_US: 'Instances',
        },
        icon: 'AiOutlineGroup',
        path: '/instances',
        permissions: [Permission.manageInstances],
    },
    {
        code: 'analytics',
        label: {
            en_US: 'Analytics',
        },
        icon: 'AiOutlineBarChart',
        path: '/analytics',
        children: [
            {
                code: 'newCustomers',
                label: {
                    en_US: 'New Customers',
                },
                icon: 'AiOutlinePlusSquare',
                path: '/analytics/new-customers',
                permissions: [Permission.listCustomers],
            },
        ],
    },
    {
        code: 'users',
        label: {
            en_US: 'Users',
        },
        icon: 'AiOutlineTeam',
        path: '/users',
        permissions: [Permission.manageUsers],
        children: [
            {
                code: 'usersList',
                label: {
                    en_US: 'Users list',
                },
                icon: 'AiOutlineMenu',
                path: '/users',
            },
            {
                code: 'usersAdd',
                icon: 'AiOutlineUser',
                label: {
                    en_US: 'Add new user',
                },
                path: '/users/add',
            },
        ],
    },
    {
        code: 'partners',
        label: {
            en_US: 'Partners',
        },
        icon: 'PiHandshakeLight',
        path: '/partners',
        permissions: [Permission.partnersList],
        condition: (user) => !user?.partnerId,
    },
    {
        code: 'channels',
        label: {
            en_US: 'Channels',
        },
        icon: 'AiOutlineApartment',
        path: '/channels',
        children: [
            {
                code: 'websiteChat',
                label: {
                    en_US: 'Website Chat',
                },
                icon: 'AiOutlineMessage',
                path: '/channels/website-chat',
                permissions: [Permission.websiteChatDeploymentsList],
                children: [
                    {
                        code: 'websiteChatDeployments',
                        label: {
                            en_US: 'Deployments',
                        },
                        icon: 'AiOutlineDeploymentUnit',
                        path: '/channels/website-chat/deployments',
                        permissions: [Permission.websiteChatDeploymentsList],
                    },
                ],
            
            },
            {
                code: 'facebook',
                label: {
                    en_US: 'Facebook',
                },
                path: '/channels/facebook',
                icon: 'AiOutlineFacebook',
                children: [
                    {
                        code: 'userTokens',
                        label: {
                            en_US: 'User Tokens',
                        },
                        icon: 'AiOutlineApi',
                        path: '/channels/facebook/tokens',
                        permissions: [Permission.listFacebookUserTokens],
                    },
                    {
                        code: 'webhooksRouter',
                        label: {
                            en_US: 'Webhooks router',
                        },
                        icon: 'AiOutlineBranches',
                        path: '/channels/facebook/webhooks',
                        permissions: [Permission.facebookWebhooksRouterList],
                    },
                ],
            },
            {
                code: 'whatsapp',
                label: {
                    en_US: 'WhatsApp',
                },
                path: '/channels/whatsapp',
                permissions: [Permission.whatsappUtils],
                icon: 'AiOutlineWhatsApp',
                children: [
                    {
                        code: 'whatsapp',
                        label: {
                            en_US: 'Register Phone Number',
                        },
                        icon: 'AiOutlinePhone',
                        path: '/channels/whatsapp/register-phone-number',
                        permissions: [Permission.whatsappUtils],
                    },
                    {
                        code: 'embeddedSignup',
                        label: {
                            en_US: 'Embedded Signup',
                        },
                        icon: 'AiOutlineUserAdd',
                        path: '/channels/whatsapp/embedded-signup',
                        permissions: [Permission.whatsappEmbeddedSignup],
                        children: [
                            {
                                code: 'createInvitation',
                                label: {
                                    en_US: 'Create Invitation',
                                },
                                icon: 'AiOutlinePlus',
                                path: '/channels/whatsapp/embedded-signup/invite',
                                permissions: [Permission.whatsappEmbeddedSignup],
                            },
                            {
                                code: 'invitationsList',
                                label: {
                                    en_US: 'Invitations List',
                                },
                                icon: 'AiOutlineOrderedList',
                                path: '/channels/whatsapp/embedded-signup/invitations',
                            },
                        ]
                    },
                ],
            },
        ],
    },
];

/**
 * useNavigationItems is a hook that returns {@link MenuList} based on the permissions of the user and optional conditions.
 *
 * @param finderFunc - Function to find the desired submenu and return its children.
 * @returns menuList, items - The filtered menu items and a function to additionally filter the menu list.
 * @see navigationItems - The original menu items.
 */
const useNavigationItems = (finderFunc?: (item: MenuList[number]) => boolean) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);

    const filterMenuList = (menuList: MenuList = []) =>
        menuList
            .filter(({ permissions = [] }) => dispatch(hasPermissions(permissions)))
            .filter(({ condition }) => !condition || (user && condition(user)));

    const findAndFilterNestedChildren = (items: MenuList = []): MenuList | undefined => {
        for (const item of items) {
            if (finderFunc?.(item)) return filterMenuList(item.children) || [];

            if (item.children) {
                const result = findAndFilterNestedChildren(filterMenuList(item.children));

                if (result) return result;
            }
        }

        return undefined;
    };

    // Filter the top-level items first
    const filteredItems = filterMenuList(navigationItems);

    // Use the finder function to retrieve children or return the filtered top-level items
    const items = finderFunc ? findAndFilterNestedChildren(filteredItems) || [] : filteredItems;

    return { items, filterMenuList };
};

export default useNavigationItems;

export interface FilterWrapperProps {
  children: React.ReactNode;
  position: "left" | "right";
  /* Re-arrenge the filters, starting from 0 (left|right) */
  order?: number;
  /* Defaults to 250, but can be overridden */
  width?: number;
}

const FilterWrapper = ({
  children,
  position,
  order = 0,
  width = 250,
}: FilterWrapperProps) => {
  const moveBy = order ? order * width + 16 : 0;

  return (
    <div
      className="action-header"
      style={{
        [position]: 32 + moveBy,
        width,
      }}
    >
      {children}
    </div>
  );
};

export default FilterWrapper;

import type { MenuList } from '../../../interface/layout/menu.interface';

import { Menu } from 'antd';
import { type FC } from 'react';
import * as antdicons from 'react-icons/ai';
import { PiHandshakeLight } from 'react-icons/pi';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import useNavigationItems from '@/hooks/useNavigationItems';
import { setUI } from '@/stores/ui.store';

const icons = { ...antdicons, PiHandshakeLight };

interface MenuProps {
    openKey?: string;
    onChangeOpenKey: (key?: string) => void;
    selectedKey: string;
    onChangeSelectedKey: (key: string) => void;
}

const MenuComponent: FC<MenuProps> = (props) => {
    const { openKey, onChangeOpenKey, selectedKey, onChangeSelectedKey } = props;
    const { device, locale, menuCollapsed } = useSelector((state) => state.ui);
    const dispatch = useDispatch();

    const { items: navigationItems, filterMenuList } = useNavigationItems();

    const getTitle = (menu: MenuList[number]) => {
        return (
            <Link className="title-container" to={menu.path}>
                <span>{menu.label[locale]}</span>
            </Link>
        );
    };

    const getIcon = (menu: MenuList[number], shouldHide?: boolean) => {
        if (shouldHide) {
            return null;
        }

        const Ic = menu.icon ? icons[menu.icon as keyof typeof icons] : null;

        return Ic && <Ic />;
    };

    const onMenuClick = (path: string) => {
        onChangeSelectedKey(path);

        if (device !== 'DESKTOP') {
            dispatch(
                setUI({
                    menuCollapsed: true,
                }),
            );
        }
    };

    const onOpenChange = (keys: string[]) => {
        const key = keys.pop();

        onChangeOpenKey(key);
    };

    return (
        <Menu
            mode="inline"
            selectedKeys={[selectedKey]}
            openKeys={openKey ? [openKey] : []}
            onOpenChange={onOpenChange}
            onSelect={(k) => onMenuClick(k.key)}
            className="layout-page-sider-menu text-2"
            items={navigationItems.map((menu) => {
                return menu.children
                    ? {
                          key: menu.code,
                          type: menu.type,
                          label: getTitle(menu),
                          icon: getIcon(menu),
                          children:
                              menu.children 
                              ? filterMenuList(menu.children).map((child) => ({
                                  key: child.path,
                                  label: getTitle(child),
                                  icon: getIcon(child, menuCollapsed),
                                  type: child.type,
                              })) as any[] // TODO: Understand why the problem with type here
                              : [],
                      }
                    : {
                          key: menu.path,
                          label: getTitle(menu),
                          icon: getIcon(menu),
                          children: [],
                      };
            })}
        ></Menu>
    );
};

export default MenuComponent;

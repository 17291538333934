import type { TableColumnsType } from 'antd';

import '../InvitationsListPage.less';

import { message, Popconfirm, Table as AntdTable, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { AiOutlineCopy } from 'react-icons/ai';
import { useSelector } from 'react-redux';

import MyButton from '@/components/basic/button';
import MyCheckBox from '@/components/basic/checkbox';
import ServicePermissionGate from '@/components/services/ServicePermissionGate';
import { LocaleFormatter, useLocale } from '@/locales';
import { history } from '@/routes/history';

// import getThemeColors from '@/utils/getThemeColors';
import { Services,WAES  } from '../../../../../../../../common';

interface Props {
    list: WAES.Invitation[];
    limit: number;
    embeddedSignupBaseUrl?: string;
    deleteInvitation: (invitationCode: string) => void;
}

const InvitationsTable: React.FC<Props> = ({ list, limit, embeddedSignupBaseUrl, deleteInvitation }) => {
    const { formatMessage } = useLocale();
    const { user } = useSelector((state) => state.user);

    const renderDate = (dateString: string) => {
        const date = dayjs(dateString),
            fullDate = date.format('lll');

        if (dayjs().diff(date, 'month') < 1) {
            return (
                <Tooltip title={fullDate} trigger={['hover', 'click']}>
                    {date.fromNow()}
                </Tooltip>
            );
        } else {
            return fullDate;
        }
    };

    const onCopyToClipboard = (invitationLink: string) => {
        navigator.clipboard.writeText(invitationLink);
        message.success(formatMessage({ id: 'global.tips.copyToClipboardSuccess' }));
    };

    const columns: TableColumnsType<WAES.Invitation> = [
        {
            title: formatMessage({ id: 'whatsapp.embeddedSignup.invitations.list.table.header.customerId' }),
            dataIndex: 'customerId',
            sorter: (a, b) => a.customerId.localeCompare(b.customerId),
        },
        {
            title: formatMessage({ id: 'whatsapp.embeddedSignup.invitations.invitationLink' }),
            dataIndex: 'code',
            sorter: (a, b) => a.code.localeCompare(b.code),
            render: (code: string) => {
                const url = `${embeddedSignupBaseUrl}/signup/${code}`;

                return (
                    <Tooltip title={url} overlayInnerStyle={{ width: 400 }}>
                        <MyButton
                            type="default"
                            size="small"
                            // color={getThemeColors().primaryGreen} // By type color code isn't supported here
                            color="primary"
                            icon={<AiOutlineCopy />}
                            className="copy-invitation-link-button"
                            onClick={() => onCopyToClipboard(url)}
                        >
                            <LocaleFormatter id="whatsapp.embeddedSignup.invitations.copyInvitationLink" />
                        </MyButton>
                    </Tooltip>
                );
            },
        },
        {
            title: formatMessage({ id: 'whatsapp.embeddedSignup.invitations.list.table.header.created' }),
            dataIndex: 'created',
            sorter: (a, b) => dayjs(a.created).unix() - dayjs(b.created).unix(),
            render: (created: string) => {
                return renderDate(created);
            },
        },
        {
            title: formatMessage({ id: 'whatsapp.embeddedSignup.invitations.list.table.header.completed' }),
            dataIndex: 'completed',
            sorter: (a, b) => {
                if (a.completed && !b.completed) {
                    return 1;
                }

                if (!a.completed && b.completed) {
                    return -1;
                }

                return 0;
            },
            render: (completed: boolean) => {
                return completed ? <MyCheckBox checked={completed} style={{ pointerEvents: 'none' }} /> : null;
            },
        },
        {
            title: formatMessage({ id: 'whatsapp.embeddedSignup.invitations.list.table.header.expires' }),
            dataIndex: 'expires',
            sorter: (a, b) => dayjs(a.expires).unix() - dayjs(b.expires).unix(),
            render: (expires: string) => {
                if (!expires) {
                    return <LocaleFormatter id="global.infininity" />;
                }

                return renderDate(expires);
            },
        },
        {
            title: formatMessage({ id: 'whatsapp.embeddedSignup.invitations.list.table.header.actions' }),
            align: 'right',
            render: (_, record) => {
                return (
                    <div className="invitations-table-actions">
                        <ServicePermissionGate
                            permissions={[WAES.Permissions.Permission.invitations_update]}
                            service={Services.Name.waesApiV1}
                            user={user!}
                        >
                            <MyButton
                                type="primary"
                                size="small"
                                onClick={() =>
                                    history.push(`/channels/whatsapp/embedded-signup/invitations/${record.code}`, {
                                        invitation: record,
                                    })
                                }
                            >
                                <LocaleFormatter id="global.edit" />
                            </MyButton>
                        </ServicePermissionGate>
                        <ServicePermissionGate
                            permissions={[WAES.Permissions.Permission.invitations_delete]}
                            service={Services.Name.waesApiV1}
                            user={user!}
                        >
                            <Popconfirm
                                title={formatMessage({ id: 'whatsapp.embeddedSignup.invitations.delete.title' })}
                                description={formatMessage({
                                    id: 'whatsapp.embeddedSignup.invitations.delete.description',
                                })}
                                placement="topLeft"
                                onConfirm={() => deleteInvitation(record.code)}
                                okText={formatMessage({ id: 'global.delete' })}
                                zIndex={1200}
                            >
                                <MyButton type="primary" size="small">
                                    <LocaleFormatter id="global.delete" />
                                </MyButton>
                            </Popconfirm>
                        </ServicePermissionGate>
                    </div>
                );
            },
        },
    ];

    return (
        <AntdTable
            columns={columns}
            dataSource={list}
            showSorterTooltip={{
                placement: 'bottomRight',
            }}
            rowKey="code"
            sortDirections={['descend', 'ascend']}
            pagination={{ pageSize: limit }}
        />
    );
};

export default InvitationsTable;
